export enum AnalyticsEvents {
  landing_page_landed = "Landing Page | Landed",
  landing_page_login_clicked = "Landing Page | Login | Clicked",
  landing_page_signup_clicked = "Landing Page | Signup | Clicked",
  landing_page_hire_clicked = "Landing Page | Hire | Clicked",
  company_create_form_landed = "Company Create Form | Landed",
  company_create_form_create_company_attempted = "Company Create Form | Create Company | Attempted",
  company_create_form_create_company_succeeded = "Company Create Form | Create Company | Succeeded",
  company_create_form_create_company_failed = "Company Create Form | Create Company | Failed",
  company_create_form_create_interview_failed = "Company Create Form | Create Interview | Failed",
  company_create_form_redirected = "Company Create Form | Redirected",
  home_landed = "Home | Landed",
  home_tab_clicked = "Home | Tab | Clicked",
  interview_list_tab_created_interview = "Interview List Tab | Created Interview",
  interview_list_tab_clicked_interview = "Interview List Tab | Clicked Interview",
  interview_list_tab_changed_language = "Interview List Tab | Changed Language",
  settings_tab_account_update_attempted = "Settings Tab | Account Update Attempted",
  settings_tab_account_update_failed = "Settings Tab | Account Update Failed",
  settings_tab_account_update_succeeded = "Settings Tab | Account Update Succeeded",
  company_tab_company_update_attempted = "Company Tab | Account Update Attempted",
  company_tab_company_update_failed = "Company Tab | Account Update Failed",
  company_tab_company_update_succeeded = "Company Tab | Account Update Succeeded",
  interview_session_landed = "Interview Session | Landed",
  interview_session_invite_sent = "Interview Session | Invite | Sent",
  interview_session_code_ran = "Interview Session | Code | Ran",
  interview_session_code_saved = "Interview Session | Code | Saved",
  interview_session_back_clicked = "Interview Session | Back | Clicked",
  interview_session_language_changed = "Interview Session | Language | Changed",
  terms_of_use_landed = "Terms of Use | Landed",
  privacy_policy_landed = "Privacy Policy | Landed",
  login_form_landed = "Login Form | Landed",
  login_form_login_attempted = "Login Form | Login | Attempted",
  login_form_login_failed = "Login Form | Login | Failed",
  login_form_login_succeeded = "Login Form | Login | Succeeded",
  login_form_login_redirected = "Login Form | Login | Redirected",
  session_invite_landed = "Session Invite | Landed",
  session_invite_redeemed = "Session Invite | Redeemed",
  session_invite_signup_attempted = "Session Invite | Signup | Attempted",
  session_invite_signup_failed = "Session Invite | Signup | Failed",
  session_invite_signup_succeeded = "Session Invite | Signup | Succeeded",
  signup_form_landed = "Signup Form | Landed",
  signup_form_signup_attempted = "Signup Form | Signup | Attempted",
  signup_form_signup_failed = "Signup Form | Signup | Failed",
  signup_form_signup_succeeded = "Signup Form | Signup | Succeeded",
  signup_form_session_invite_redeemed = "Signup Form | Session Invite | Redeemed",
  signup_form_session_invite_redemption_failed = "Signup Form | Session Invite | Redemption failed",
  signup_form_redirected = "Signup Form | Redirected",
}

// Generic

export interface NewEntityResponse<T = undefined> {
  created_id: string
  data?: T
}

export interface SuccessResponse {
  success: boolean
  message?: string
}

// Accounts

export enum AccountOnboardingStatus {
  create_company = "create_company",
  subscribe = "subscribe",
  complete = "complete",
}

export interface AccountMiddlewareData {
  account_id: string
}

export interface AccountDatabaseModel {
  account_id: string
  email: string
  account_password: string
  first_name: string
  last_name: string
  confirmation_code: string
  confirmation_code_expiry: Date
  is_confirmed: boolean
  onboarding_status: AccountOnboardingStatus
  created_at: Date
  updated_at: Date
  deleted_at: Date
}

export interface AccountConfirmationData {
  account_id: string
  confirmation_code: string
  confirmation_code_expiry: string
  is_confirmed: boolean
}

export interface AccountLoginData {
  account_id: string
  account_password: string
}

export interface AccountData {
  account_id: string
  email: string
  first_name: string
  last_name: string
  is_confirmed: boolean
  onboarding_status: AccountOnboardingStatus
  created_at: Date
}

export interface AccountInfo {
  first_name: string
  last_name: string
}

export interface AccountLoginRequest {
  email: string
  account_password: string
}

export interface AccountLoginResponse {
  token: string
}

export interface AccountCreateRequestBase {
  email: string
  first_name: string
  last_name: string
  account_password: string
}

export interface AccountCreateRequest extends AccountCreateRequestBase {
  is_nontechnical_cofounder: boolean
  is_hiring_software_engineers: boolean
  hdyhau: string
}

export interface AccountCreateWithSessionInviteIdRequest
  extends AccountCreateRequestBase {
  session_invite_id: string
}

export interface AccountUpdateRequest {
  first_name?: string
  last_name?: string
}

// Companies

export enum CompanySizes {
  oneToTen = "oneToTen",
  elevenToFifty = "elevenToFifty",
  fiftyPlus = "fiftyPlus",
}

export interface CompanyRequest {
  company_id: string
}

export interface CompanyCreateRequest {
  company_name: string
  company_size: string
  company_website: string
}

export interface CompanyUpdateRequest {
  company_name?: string
  company_size?: string
  company_website?: string
}

export interface CompanyData {
  company_id: string
  company_name: string
  company_size: string
  company_website: string
  created_at: Date
}

// Company Accounts

export enum CompanyRoles {
  admin = "admin",
  member = "member",
}

export interface CompanyAccountCreateRequest extends CompanyRequest {
  account_id: string
  company_role: CompanyRoles
}

export interface CompanyAccountUpdateRequest extends CompanyRequest {
  account_id: string
  company_role: CompanyRoles
}

export interface CompanyAccountData {
  company_id: string
  account_id: string
  company_role: CompanyRoles
  created_at: Date
}

export interface CompanyAccountsJoinData {
  company_id: string
  account_id: string
  company_role: CompanyRoles
  company_name: string
  company_size: string
  company_website: string
  created_at: Date
}

// Company Invites

export interface CompanyInviteCreateRequest extends CompanyRequest {
  email: string
  company_role: CompanyRoles
}

export interface CompanyInviteUpdateRequest {
  accepted_account_id: string
  accepted_at: Date
}

export interface CompanyInviteData {
  company_id: string
  email: string
  company_role: CompanyRoles
  expires_at: Date
  accepted_account_id: string
  accepted_at: Date
  created_at: Date
}

// Roles

export interface RoleRequest {
  role_id: string
}

export interface RoleCreateRequest extends CompanyRequest {
  title: string
  plan?: string
}

export interface RoleUpdateRequest {
  role_id: string
  plan?: string
  hired_at?: Date
}

export interface RoleData {
  role_id: string
  company_id: string
  title: string
  plan: string
  hired_at: Date
  created_at: Date
}

// Interviews

export enum InterviewDecision {
  failed = "failed",
  in_progress = "in_progress",
  passed = "passed",
}

export interface InterviewCreateRequest {
  company_id: string
  language: string
  role_id?: string
}

export interface InterviewUpdateRequest {
  interview_template_id?: string
  language?: string
  code?: string
  decision?: InterviewDecision
  notes?: string
}

export interface InterviewData {
  interview_id: string
  role_id: string
  company_id: string
  language: string
  code: string
  decision: InterviewDecision
  notes: string
  created_at: Date
}

export interface EnrichedInterviewData {
  interview_id: string
  role_id: string
  company_id: string
  language: string
  code: string
  decision: InterviewDecision
  notes: string
  created_at: Date
  participants?: InterviewParticipant[]
  company_name?: string
}

export interface InterviewParticipant {
  interview_id: string
  first_name: string
  last_name: string
}

// Code

export interface CodeRunResults {
  stdout: string
  code: number
}

export interface CodeRunRequest {
  session_id: string
  code: string
  language: string
}

export interface CodeRunResponse {
  message: string
  data?: CodeRunResults
}

export interface SessionCodeRunData {
  session_code_run_id: string
  session_id: string
  runner_account_id: string
  code: string
  language: string
  stdout: string
  created_at: Date
  ran_at?: Date
}

// Session Invites

export enum SessionTypes {
  interview = "interview",
}

export interface SessionInviteCreateRequest {
  session_id: string
  session_type: SessionTypes
  email?: string
  account_id?: string
}

export interface SessionInviteData {
  session_invite_id: string
  session_id: string
  session_type: SessionTypes
  email?: string
  account_id?: string
  expires_at: Date
  accepted_account_id?: string
  accepted_at?: Date
  created_at?: Date
}

export interface SessionInviteJoinData extends SessionInviteData {
  first_name?: string
  last_name?: string
}

export interface SessionInviteRedeemRequest {
  session_invite_id: string
}

// Interview Templates

export enum InterviewStepType {
  intro = "intro",
  interview_question = "interview_question",
  live_interview = "live_interview",
}

export interface InterviewTemplateStep {
  step_type: InterviewStepType
  interview_question_id?: string
}

export interface InterviewTemplateCreateRequest {
  role_id?: string
  title: string
  steps: InterviewTemplateStep[]
}

export interface InterviewTemplateUpdateRequest {
  title?: string
  steps?: InterviewTemplateStep[]
}

export interface InterviewTemplateData {
  interview_template_id: string
  role_id?: string
  steps: InterviewTemplateStep[]
}

// Interview Steps

export enum InterviewStepStatus {
  ready = "ready",
  in_progress = "in_progress",
  complete = "complete",
}

export interface InterviewStepEvaluation {
  interviewer_account_id: string
  score: number
  report: string
}

export interface InterviewStepCreateRequest {
  title: string
  interview_step_type: InterviewStepType
  interview_id?: string
  interview_question_id?: string
}

export interface InterviewStepUpdateRequest {
  interview_question_id?: string
  interview_step_type?: string
  interview_account_id?: string
  status?: InterviewStepStatus
  evaluation?: InterviewStepEvaluation
  evaluation_criteria?: string
}

export interface InterviewStepData {
  interview_step_id: string
  title: string
  interview_step_type: InterviewStepType
  interview_id: string
  interview_question_id: string
  interview_question_attempt_id: string
  interviewer_account_id: string
  status: InterviewStepStatus
  evaluation: InterviewStepEvaluation
  evaluation_criteria: string
  created_at: Date
}

// Interview Questions

export interface InterviewQuestionCreateRequest {
  prompt: string
  topics: string[]
  difficulty: string
  input_output: JSON
}

export interface InterviewQuestionData {
  interview_question_id?: string
  prompt: string
  topics: string[]
  difficulty: string
  input_output: Object
}
