import React, { createContext, useEffect, useState } from "react"
import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react"
import { initAmplitude, setAmplitudeUserId } from "../services/amplitude"
import { ApiClient } from "../services/api"
import {
  AccountData,
  AccountOnboardingStatus,
  CompanyData,
  CompanySizes,
} from "../services/types"

const theme = extendTheme({
  styles: {
    global: {
      body: {},
      div: {},
      a: {},
    },
  },
})

export interface ApiWrapper {
  client: ApiClient
  setApi: Function
}

export interface UserWrapper {
  data: AccountData
  setUser: Function
}

export interface CompanyWrapper {
  data: CompanyData
  setCompany: Function
}

export const defaultApi: ApiWrapper = {
  client: new ApiClient(""),
  setApi: () => {},
}

export const defaultUser: UserWrapper = {
  data: {
    account_id: "",
    email: "",
    first_name: "",
    last_name: "",
    is_confirmed: false,
    onboarding_status: AccountOnboardingStatus.create_company,
    created_at: new Date(),
  },
  setUser: () => {},
}

export const defaultCompany: CompanyWrapper = {
  data: {
    company_id: "",
    company_name: "",
    company_size: CompanySizes.oneToTen,
    company_website: "",
    created_at: new Date(),
  },
  setCompany: () => {},
}

export const ApiContext = createContext(defaultApi)
export const UserContext = createContext(defaultUser)
export const CompanyContext = createContext(defaultCompany)

export default function Root(props) {
  const [userData, setUserData] = useState(defaultUser.data)
  const [companyData, setCompanyData] = useState(defaultCompany.data)
  const [apiClient, setApiClient] = useState(defaultApi.client)

  useEffect(() => {
    initAmplitude()
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token")
      let cachedUserData
      let cachedCompanyData
      try {
        cachedUserData = JSON.parse(localStorage.getItem("user"))
      } catch (err) {}
      try {
        cachedCompanyData = JSON.parse(localStorage.getItem("company"))
      } catch (err) {}

      if (token) {
        setApiClient(new ApiClient(token))
      }

      if (cachedUserData?.account_id) {
        setAmplitudeUserId(cachedUserData.account_id)
        setUserData(cachedUserData)
      }

      if (cachedCompanyData?.company_id) {
        setCompanyData(cachedCompanyData)
      }
    }
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <ApiContext.Provider
        value={{
          client: apiClient,
          setApi: (api: ApiClient) => {
            if (typeof window !== "undefined") {
              localStorage.setItem("token", api.token)
            }
            setApiClient(api)
          },
        }}
      >
        <UserContext.Provider
          value={{
            data: userData,
            setUser: (user: AccountData) => {
              if (typeof window !== "undefined") {
                localStorage.setItem("user", JSON.stringify(user))
              }
              setAmplitudeUserId(user?.account_id)
              setUserData(user)
            },
          }}
        >
          <CompanyContext.Provider
            value={{
              data: companyData,
              setCompany: (company: CompanyData) => {
                if (typeof window !== "undefined") {
                  localStorage.setItem("company", JSON.stringify(company))
                }
                setCompanyData(company)
              },
            }}
          >
            {props.children}
          </CompanyContext.Provider>
        </UserContext.Provider>
      </ApiContext.Provider>
    </ChakraProvider>
  )
}
