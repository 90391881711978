import { ApiBase } from "./api_base"
import {
  AccountCreateRequest,
  AccountCreateWithSessionInviteIdRequest,
  AccountData,
  AccountInfo,
  AccountLoginRequest,
  AccountLoginResponse,
  AccountUpdateRequest,
  CodeRunRequest,
  CodeRunResponse,
  CompanyCreateRequest,
  CompanyData,
  CompanyUpdateRequest,
  InterviewCreateRequest,
  InterviewData,
  InterviewParticipant,
  InterviewUpdateRequest,
  NewEntityResponse,
  RoleCreateRequest,
  RoleData,
  RoleUpdateRequest,
  SessionCodeRunData,
  SessionInviteCreateRequest,
  SessionInviteData,
  SessionInviteJoinData,
  SessionInviteRedeemRequest,
  SuccessResponse,
} from "./types"

export class ApiClient extends ApiBase {
  async ping() {
    return await this.get("/ping")
  }

  async createAccount(
    createRequest: AccountCreateRequest
  ): Promise<NewEntityResponse<AccountLoginResponse>> {
    return await this.post<NewEntityResponse<AccountLoginResponse>>(
      "/account",
      createRequest
    )
  }

  async createAccountWithSessionInvite(
    createRequest: AccountCreateWithSessionInviteIdRequest
  ): Promise<NewEntityResponse<AccountLoginResponse>> {
    return await this.post<NewEntityResponse<AccountLoginResponse>>(
      "/account/session_invite",
      createRequest
    )
  }

  async login(
    loginRequest: AccountLoginRequest
  ): Promise<AccountLoginResponse> {
    return await this.post<AccountLoginResponse>("/account/login", loginRequest)
  }

  async getAccount(): Promise<AccountData> {
    return await this.get("/account")
  }

  async updateAccount(
    updateRequest: AccountUpdateRequest
  ): Promise<SuccessResponse> {
    return await this.put("/account", updateRequest)
  }

  async createCompany(
    createRequest: CompanyCreateRequest
  ): Promise<NewEntityResponse> {
    return await this.post<NewEntityResponse>("/company", createRequest)
  }

  async updateCompany(
    companyId: string,
    updateRequest: CompanyUpdateRequest
  ): Promise<SuccessResponse> {
    return await this.put(`/company/${companyId}`, updateRequest)
  }

  async getCompanyById(companyId: string): Promise<CompanyData> {
    return await this.get<CompanyData>(`/company/${companyId}`)
  }

  async getCompaniesForAccount(): Promise<CompanyData[]> {
    return await this.get<CompanyData[]>("/account/company")
  }

  async createRole(
    createRequest: RoleCreateRequest
  ): Promise<NewEntityResponse> {
    return await this.post<NewEntityResponse>("/role", createRequest)
  }

  async updateRole(
    companyId: string,
    updateRequest: RoleUpdateRequest
  ): Promise<SuccessResponse> {
    return await this.put<SuccessResponse>(
      `/role/company/${companyId}`,
      updateRequest
    )
  }

  async createInterview(
    createRequest: InterviewCreateRequest
  ): Promise<NewEntityResponse> {
    return await this.post("/interview", createRequest)
  }

  async updateInterview(
    interviewId: string,
    updateRequest: InterviewUpdateRequest
  ): Promise<SuccessResponse> {
    return await this.put(`/interview/${interviewId}`, updateRequest)
  }

  async getInterviewsForCompany(companyId: string): Promise<InterviewData[]> {
    return await this.get(`/company/${companyId}/interview`)
  }

  async runCode(runRequest: CodeRunRequest): Promise<CodeRunResponse> {
    return await this.post(`/session_code_run`, runRequest)
  }

  async getRolesByCompany(companyId: string): Promise<RoleData[]> {
    return await this.get<RoleData[]>(`/role/company/${companyId}`)
  }

  async getSessionCodeRuns(interviewId: string): Promise<SessionCodeRunData[]> {
    return await this.get(`/session_code_run/${interviewId}`)
  }

  async createInterviewSessionInvite(
    companyId: string,
    createRequest: SessionInviteCreateRequest
  ): Promise<NewEntityResponse> {
    return await this.post(
      `/company/${companyId}/interview/invite`,
      createRequest
    )
  }

  async getInterviewSessionInvitesBySessionId(
    companyId: string,
    sessionId: string
  ): Promise<SessionInviteJoinData[]> {
    return await this.get(`/company/${companyId}/interview/${sessionId}/invite`)
  }

  async getInterviewSessionInvitesBySessionIdForCandidate(
    interviewId: string
  ): Promise<SessionInviteData[]> {
    return await this.get(`/interview/${interviewId}/session_invite`)
  }

  async getAccounts(accountIds: string[]): Promise<AccountInfo[]> {
    return await this.post("/accounts/info", accountIds)
  }

  async getSessionInviteById(
    sessionInviteId: string
  ): Promise<SessionInviteData> {
    return await this.get(`/session_invite/${sessionInviteId}`)
  }

  async getSessionsForAccount(): Promise<SessionInviteData[]> {
    return await this.get(`/session_invite/account`)
  }

  async redeemSessionInvite(
    request: SessionInviteRedeemRequest
  ): Promise<SuccessResponse> {
    return await this.put("/session_invite/redeem", request)
  }

  async getInterviewById(interviewId: string): Promise<InterviewData> {
    return await this.get(`/interview/${interviewId}`)
  }

  async getInterviewsBySessionInvite(): Promise<InterviewData[]> {
    return await this.get("/interview/session_invite")
  }

  async getInterviewParticipants(
    companyId: string
  ): Promise<{ [key: string]: InterviewParticipant[] }[]> {
    return await this.get(`/company/${companyId}/interview/participants`)
  }
}
