import amplitude from "amplitude-js"

export const initAmplitude = () => {
  // @ts-ignore
  console.log("amplitude key", process.env.GATSBY_AMPLITUDE_API_KEY)
  // @ts-ignore
  return amplitude.getInstance().init(process.env.GATSBY_AMPLITUDE_API_KEY)
}

export const setAmplitudeUserDevice = installationToken => {
  // @ts-ignore
  amplitude.getInstance().setDeviceId(installationToken)
}

export const setAmplitudeUserId = userId => {
  // @ts-ignore
  amplitude.getInstance().setUserId(userId)
}

export const setAmplitudeUserProperties = properties => {
  // @ts-ignore
  amplitude.getInstance().setUserProperties(properties)
}

export const sendAmplitudeEvent = (eventType, eventProperties) => {
  // @ts-ignore
  amplitude.getInstance().logEvent(eventType, eventProperties)
  console.log(`Logging event ${eventType}`)
}

export const sendDelayedAmplitudeEvent = (eventType, eventProperties) => {
  setTimeout(() => {
    sendAmplitudeEvent(eventType, eventProperties)
  }, 500)
}
