// TODO retype this cause we return the response on error
export class ApiBase {
  token: string

  constructor(token: string) {
    this.token = token
  }

  applyCachedToken() {
    if (typeof window !== "undefined") {
      const token = window.localStorage.getItem("token")
      if (!!token && !this.token) {
        this.token = token
      }
    }
  }

  async get<T>(
    path: string,
    headers: HeadersInit = {},
    errorHandler: Function = res => {
      return
    }
  ): Promise<T> {
    this.applyCachedToken()
    if (!!this.token) {
      headers["Authorization"] = this.token
    }
    headers["Content-Type"] = "application/json"
    const response = await fetch(`${process.env.GATSBY_API_URL}${path}`, {
      headers,
    })
    if (response.status !== 200) {
      return errorHandler(response)
    }
    return await response.json()
  }

  async post<T>(
    path: string,
    data: object,
    headers: HeadersInit = {},
    errorHandler: Function = res => {
      return res
    }
  ): Promise<T> {
    this.applyCachedToken()
    if (!!this.token) {
      headers["Authorization"] = this.token
    }
    headers["Content-Type"] = "application/json"
    try {
      const response = await fetch(`${process.env.GATSBY_API_URL}${path}`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      })

      if (response.status > 299 || response.status < 200) {
        return errorHandler(response)
      }
      return await response.json()
    } catch (err) {
      console.log("post err", err)
    }
  }

  async put<T>(
    path: string,
    data: object,
    headers: HeadersInit = {},
    errorHandler: Function = res => {
      return res
    }
  ): Promise<T> {
    this.applyCachedToken()
    if (!!this.token) {
      headers["Authorization"] = this.token
    }
    headers["Content-Type"] = "application/json"
    try {
      const response = await fetch(`${process.env.GATSBY_API_URL}${path}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(data),
      })

      if (response.status > 299 || response.status < 200) {
        return errorHandler(response)
      }
      return await response.json()
    } catch (err) {
      console.log("post err", err)
    }
  }
}
